import { useMemo } from 'react'

import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { RefundedScreen } from '@/modules/checkout/screens/refunded'
import { useLinkId } from '@/shared/hooks/use-link-id'

const RefundedPage = () => {
  const { t } = useTranslation()
  const title = useMemo<string>(() => t('Pagamento estornado'), [t])
  const id = useLinkId()

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <RefundedScreen id={id} title={title} />
    </>
  )
}

export default RefundedPage
