import 'reflect-metadata'
import { Container } from 'inversify'

import { FindLinkByIdService } from '@/modules/checkout/_core/services/find-link-by-id'
import { PaymentLinkByIdService } from '@/modules/checkout/_core/services/payment-link-by-id'
import { customApi } from '@/shared/_core/api'
import type { HttpClient } from '@/shared/_core/infra/http/protocols'

export const linkRegistry = {
  HttpClient: Symbol.for('HttpClient'),
  FindLinkByIdService: Symbol.for('FindLinkByIdService'),
  PaymentLinkByIdService: Symbol.for('PaymentLinkByIdService'),
}

const linkContainer = new Container()

linkContainer.bind(linkRegistry.HttpClient).toDynamicValue(() => customApi({}))

linkContainer
  .bind(linkRegistry.FindLinkByIdService)
  .toDynamicValue((context) => {
    return new FindLinkByIdService(
      context.container.get<HttpClient>(linkRegistry.HttpClient),
    )
  })

linkContainer
  .bind(linkRegistry.PaymentLinkByIdService)
  .toDynamicValue((context) => {
    return new PaymentLinkByIdService(
      context.container.get<HttpClient>(linkRegistry.HttpClient),
    )
  })

export { linkContainer }
