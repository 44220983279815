import { cloneElement, useCallback } from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import type { FieldProps } from './types'

export const Field = ({
  children,
  name,
  passError = true,
  defaultValue,
  triggerOnChange = false,
  onFocus,
  parseValue,
}: FieldProps) => {
  const form = useFormContext()

  const handleChange = useCallback(
    (cb: (value: string[] | string | number | boolean) => void) =>
      (newValue: unknown) => {
        cb(newValue as string | number | boolean)

        if (triggerOnChange) {
          form.trigger(name)
        }
      },
    [form, name, triggerOnChange],
  )

  return (
    <Controller
      control={form.control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, name, value, ref },
        fieldState: { error },
      }) => {
        return cloneElement(children, {
          name,
          onChange: handleChange(onChange),
          onBlur,
          onFocus,
          value: parseValue ? parseValue(value) : value,
          defaultValue,
          ref,
          error: passError ? (error?.message as string) : undefined,
        })
      }}
    />
  )
}
