import { HelmetProvider } from 'react-helmet-async'
import { Outlet } from 'react-router'

import { RootLayout } from '@/shared/layouts/Default'
import { QueryClientProvider } from '@/shared/providers'

const MyApp = () => {
  return (
    <HelmetProvider>
      <QueryClientProvider>
        <RootLayout>
          <Outlet />
        </RootLayout>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default MyApp
