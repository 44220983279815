import { Payment } from '@/modules/checkout/_core/domain/entities'
import { errorHandler, responseHandler } from '@/shared/_core/api'
import type { HttpClient } from '@/shared/_core/infra/http/protocols'

import { toEntity } from './mappers'
import type { PaymentLinkByIdServiceTypes } from './types'

export class PaymentLinkByIdService {
  private readonly httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(
    id: string,
    data: PaymentLinkByIdServiceTypes.PaymentParams,
  ): Promise<PaymentLinkByIdServiceTypes.Model> {
    return this.httpClient
      .post<
        PaymentLinkByIdServiceTypes.PaymentParams,
        PaymentLinkByIdServiceTypes.PaymentResponse
      >(`/links/${id}/pay/`, data)
      .then((response) => {
        return responseHandler<
          PaymentLinkByIdServiceTypes.PaymentResponse,
          Payment
        >(response, toEntity)
      })
      .catch((err) => errorHandler(err))
  }
}
