import { useCallback, useEffect, useMemo, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import type { Focused } from 'react-credit-cards-2'
import { Controller, useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  Input,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
} from '@maistodos/design-system-web'

import { FeedbackModal } from '@/components/FeedbackModal'
import { Field } from '@/components/Forms/Field'
import { PaymentOption } from '@/modules/checkout/_core/domain/entities'
import { PaymentStatus } from '@/modules/checkout/_core/domain/entities/payment-status'
import { CardPreview } from '@/modules/checkout/components/CardPreview'
import { SectionGroup } from '@/modules/checkout/components/Section/SectionGroup'
import { SectionText } from '@/modules/checkout/components/Section/SectionText'
import { SectionTitle } from '@/modules/checkout/components/Section/SectionTitle'
import { useBrowserInfo } from '@/modules/checkout/hooks/use-browser-info'
import { useLink } from '@/modules/checkout/hooks/use-link'
import { useModal } from '@/modules/checkout/hooks/use-modal'
import { usePay } from '@/modules/checkout/hooks/use-pay'
import { PaymentFlowAnalytics } from '@/shared/utils/analytics'
import { formatLocaleCurrency } from '@/shared/utils/format-locale'

import { masks, ONE_INSTALLMENT } from './constants'
import { FormCardData, createFormCardSchema } from './schemas'
import { Column, Form } from './styles'
import { FormCardProps } from './types'

export const FormCard = ({
  id,
  defaultCard,
  showSetDefaultCard,
  creditCard,
  debitCard,
  installments,
}: FormCardProps) => {
  const { t, i18n } = useTranslation()
  const browserInfo = useBrowserInfo()

  const [focused, setFocused] = useState<Focused | undefined>()
  const [issuer, setIssuer] = useState<string>('unknown')

  const { mutate, isPending, data: paymentData, error } = usePay(id)

  const { refetch } = useLink(id)

  const formattedInstallments = useMemo(() => {
    return installments.map((installment) => {
      const [key] = Object.keys(installment)
      const { value } = installment[key]
      const price = parseFloat(value)

      return {
        key,
        price: formatLocaleCurrency(price, i18n.language),
      }
    })
  }, [i18n.language, installments])

  const initialInstallment = useMemo<number>(() => {
    if (formattedInstallments.length === 0) {
      return ONE_INSTALLMENT
    }

    const [firstInstallment] = formattedInstallments

    return Number(firstInstallment.key)
  }, [formattedInstallments])

  const methods = useForm<FormCardData>({
    resolver: zodResolver(createFormCardSchema(t)),
    defaultValues: {
      isDefault: false,
      card: creditCard ? PaymentOption.CreditCard : PaymentOption.DebitCard,
      number: import.meta.env.DEV ? '4111 1111 1111 1111' : '',
      expDate: import.meta.env.DEV ? '12/32' : '',
      holder: import.meta.env.DEV ? 'Frodo Baggins' : '',
      cvc: import.meta.env.DEV ? '123' : '',
      installments: String(initialInstallment),
    },
  })

  const { control, handleSubmit, setValue, watch } = methods

  const card = watch('card')
  const watchedInstallments = watch('installments')

  const {
    isOpen: isOpenErrorModal,
    open: openErrorModal,
    close: closeErrorModal,
  } = useModal()

  const errorTitle = useMemo(() => {
    if (error?.needs_to_pay_with_max_installments?.length) {
      return error.needs_to_pay_with_max_installments[0]
    }

    return t('Não foi possível processar seu pagamento.')
  }, [error, t])

  const onSubmit = handleSubmit((data: FormCardData) => {
    PaymentFlowAnalytics.onClickPayment()

    const [month, year] = data.expDate.split('/')
    const paymentTypes = {
      [PaymentOption.CreditCard]: creditCard?.id,
      [PaymentOption.DebitCard]: debitCard?.id,
    } as Record<PaymentOption, number>

    let three_ds2_data = undefined
    if (data.card === PaymentOption.DebitCard) {
      three_ds2_data = {
        browserInfo: {
          acceptHeader: browserInfo?.acceptHeader,
          colorDepth: browserInfo?.colorDepth,
          language: browserInfo?.language,
          javaEnabled: browserInfo?.javaEnabled,
          screenHeight: browserInfo?.screenHeight,
          screenWidth: browserInfo?.screenWidth,
          userAgent: browserInfo?.userAgent,
          timeZoneOffset: browserInfo?.timeZoneOffset,
        },
      }
    }

    mutate(
      {
        payment_type: paymentTypes[data.card],
        card: {
          is_default: data.isDefault,
          number: data.number.replace(/ /g, ''),
          exp_date: `20${year}-${month}`,
          cvv: data.cvc,
          holder: data.holder,
        },
        installments: parseInt(data.installments),
        three_ds2_data,
      },
      {
        onSettled(response) {
          if (response?.status === PaymentStatus.Paid) {
            refetch()
            PaymentFlowAnalytics.onApprovedPayment(data.card)
            return
          }

          openErrorModal()
        },
      },
    )
  })

  const onChangeType = useCallback(
    (event_: React.ChangeEvent<HTMLInputElement>) => {
      const value = event_.target.value
      PaymentFlowAnalytics.onClickCardType(value)

      setValue(
        'card',
        event_.target.value as
          | PaymentOption.CreditCard
          | PaymentOption.DebitCard,
      )

      if (event_.target.value === PaymentOption.DebitCard) {
        setValue('installments', String(ONE_INSTALLMENT))
        return
      }

      setValue('installments', String(initialInstallment))
    },
    [initialInstallment, setValue],
  )

  const onFocus = useCallback((e: React.FocusEvent<HTMLElement>) => {
    const target = e.target as typeof e.target & {
      name: Focused
    }

    setFocused(target.name)
  }, [])

  useEffect(() => {
    PaymentFlowAnalytics.onClickInstallments(watchedInstallments)
  }, [watchedInstallments])

  return (
    <div>
      <FormProvider {...methods}>
        <Form data-testid="form-card" onSubmit={onSubmit}>
          <Column direction="column">
            <Controller
              control={control}
              name="card"
              render={({ field: { value, name } }) => (
                <RadioGroup direction="row" name={name}>
                  <Radio
                    label="Crédito"
                    disabled={!creditCard || isPending}
                    value={PaymentOption.CreditCard}
                    checked={value === PaymentOption.CreditCard}
                    onClick={onChangeType}
                  />
                  <Radio
                    label="Débito"
                    disabled={!debitCard || isPending}
                    value={PaymentOption.DebitCard}
                    checked={value === PaymentOption.DebitCard}
                    onClick={onChangeType}
                  />
                </RadioGroup>
              )}
            />

            <SectionGroup>
              <SectionTitle>{t('Dados do cartão')}</SectionTitle>
              <SectionText>{t('Informe os dados do seu cartão')}</SectionText>
            </SectionGroup>

            <Field name="number" onFocus={onFocus}>
              <Input
                label={t('Número do cartão')}
                placeholder={t('Digite o número do cartão')}
                mask={masks[issuer]?.number ?? masks.default.number}
                disabled={isPending}
              />
            </Field>

            <div className="flex justify-between gap-spacing16">
              <Field name="expDate" onFocus={onFocus}>
                <Input
                  label={t('Validade')}
                  placeholder={t('MM/AA')}
                  mask={'dd/dd'}
                  disabled={isPending}
                />
              </Field>

              <Field name="cvc" onFocus={onFocus}>
                <Input
                  label={t('CVV')}
                  placeholder={'123'}
                  mask={masks[issuer]?.cvc ?? masks.default.cvc}
                  disabled={isPending}
                />
              </Field>
            </div>

            <Field name="holder" onFocus={onFocus}>
              <Input
                label={t('Nome como no cartão')}
                placeholder={t('Digite seu nome como no cartão')}
                disabled={isPending}
              />
            </Field>

            {card === PaymentOption.CreditCard && (
              <Field
                name="installments"
                onFocus={onFocus}
                parseValue={(value) => value.toString()}
              >
                <Select
                  label={t('Parcelas')}
                  optionsMaxHeight={140}
                  scrollbarSize={6}
                  testID="installments"
                  placeholder={t('Selecione as opções')}
                  disabled={isPending}
                >
                  {formattedInstallments.map((installment) => (
                    <SelectItem key={installment.key} value={installment.key}>
                      {installment.key}x de {installment.price}
                    </SelectItem>
                  ))}
                </Select>
              </Field>
            )}

            {showSetDefaultCard && (
              <Field name="isDefault" onFocus={onFocus}>
                <Checkbox label={defaultCard} disabled={isPending} />
              </Field>
            )}
          </Column>

          <Column direction="column" className="justify-end">
            <CardPreview focused={focused} setIssuer={setIssuer} />
            <Button
              type="submit"
              loading={
                isPending ||
                (paymentData && paymentData?.status !== PaymentStatus.Failure)
              }
            >
              {t('Pagar')}
            </Button>
          </Column>
        </Form>
      </FormProvider>

      <FeedbackModal
        type="error"
        isOpen={isOpenErrorModal}
        onClose={closeErrorModal}
        title={errorTitle}
        description={t(
          'Verifique as informações do seu pagamento e tente novamente.',
        )}
        footer={
          <Button variant="secondary" fullWidth onClick={closeErrorModal}>
            Fechar
          </Button>
        }
      />
    </div>
  )
}
