import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'

import { Info } from '@maistodos/core-icons'
import {
  Box,
  Button,
  Skeleton,
  toast,
  Typography,
} from '@maistodos/design-system-web'

import {
  AlertContainer,
  AlertContent,
  AlertIconWrapper,
  AlertMessage,
  AlertTitle,
} from '@/components/Alert'
import { FeedbackModal } from '@/components/FeedbackModal'
import {
  LinkStatus,
  PaymentOption,
} from '@/modules/checkout/_core/domain/entities'
import { SectionGroup } from '@/modules/checkout/components/Section/SectionGroup'
import { SectionText } from '@/modules/checkout/components/Section/SectionText'
import { SectionTitle } from '@/modules/checkout/components/Section/SectionTitle'
import { useLink } from '@/modules/checkout/hooks/use-link'
import { useModal } from '@/modules/checkout/hooks/use-modal'
import { usePayGenerate } from '@/modules/checkout/hooks/use-pay-generate'
import { PaymentFlowAnalytics } from '@/shared/utils/analytics'
import { copyToClipboard } from '@/shared/utils/copy-to-clipboard'
import { formatLocaleCurrency } from '@/shared/utils/format-locale'

import { FIVE_SECONDS, REFETCH_RUN_LIMIT } from './constants'
import { FormPixProps } from './types'

export const FormPix = ({ id, amount, paymentType }: FormPixProps) => {
  const { t, i18n } = useTranslation()

  const refetchTimes = useRef<number>(0)
  const [refetchInterval, setRefetchInterval] = useState<number | false>(0)

  const {
    isOpen: isOpenErrorModal,
    open: openErrorModal,
    close: closeErrorModal,
  } = useModal()

  const { data, isPending, error, isError } = usePayGenerate(
    PaymentOption.Pix,
    id,
    paymentType.id,
  )

  const code = useMemo(() => data?.pix?.qrCode || '', [data])

  const formatted = useMemo<string>(
    () => formatLocaleCurrency(amount, i18n.language),
    [amount, i18n.language],
  )

  const errorTitle = useMemo(() => {
    if (error?.needs_to_pay_with_max_installments?.length) {
      return error.needs_to_pay_with_max_installments[0]
    }

    return t('Não foi possível processar seu pagamento.')
  }, [error, t])

  const onCopy = useCallback(async () => {
    PaymentFlowAnalytics.onClickCopyCode()

    await copyToClipboard(code)

    toast.success({
      message: t('Cópia realizada com sucesso!'),
      duration: 3000,
    })
  }, [code, t])

  useEffect(() => {
    if (isError) {
      openErrorModal()
    }
  }, [isError, openErrorModal])

  // Long-polling to automatically redirect to the receipt screen if Pix got confirmed
  const { isFetching, data: link } = useLink(id, {
    notifyOnChangeProps: ['isFetching', 'data'],
    refetchInterval,
  })

  useEffect(() => {
    if (code) {
      setRefetchInterval(FIVE_SECONDS)
    }
  }, [code])

  useEffect(() => {
    if (isFetching) {
      refetchTimes.current++
    }

    if (refetchTimes.current >= REFETCH_RUN_LIMIT) {
      setRefetchInterval(false)
    }
  }, [isFetching])

  useEffect(() => {
    if (!!link && link.status === LinkStatus.Paid) {
      PaymentFlowAnalytics.onApprovedPayment(PaymentOption.Pix)
    }
  }, [link])

  return (
    <div>
      {isPending && (
        <div className="flex w-full flex-col gap-spacing24 md:w-1/2">
          <SectionGroup>
            <SectionTitle>{t('Valor do PIX')}</SectionTitle>
            <SectionText>{formatted}</SectionText>
          </SectionGroup>

          <SectionGroup>
            <SectionTitle>{t('Chave copia e cola:')}</SectionTitle>
            <Skeleton height={16} />
            <Skeleton height={16} />
            <Skeleton height={16} />
            <Skeleton height={16} />
          </SectionGroup>

          <Button variant="primary" fullWidth disabled loading>
            {t('Copiar chave')}
          </Button>

          <div className="flex flex-col items-center gap-spacing4">
            <Skeleton height={180} width={180} />
            <div className="flex flex-col items-center gap-spacing2">
              <Skeleton height={12} width={150} />
              <Skeleton height={12} width={50} />
            </div>
          </div>
        </div>
      )}

      {isError && (
        <AlertContainer variant="warning">
          <AlertIconWrapper>
            <Info color="currentColor" fontSize="24px" />
          </AlertIconWrapper>
          <AlertContent>
            {errorTitle ? <AlertTitle>{errorTitle}</AlertTitle> : null}
            <AlertMessage>
              {t(
                'Verifique as informações do seu pagamento e tente novamente.',
              )}
            </AlertMessage>
          </AlertContent>
        </AlertContainer>
      )}

      {code ? (
        <div className="flex w-full flex-col gap-spacing24 md:w-1/2">
          <SectionGroup>
            <SectionTitle>{t('Valor do PIX')}</SectionTitle>
            <SectionText>{formatted}</SectionText>
          </SectionGroup>

          <SectionGroup>
            <SectionTitle>{t('Chave copia e cola:')}</SectionTitle>
            <SectionText className="break-words">{code}</SectionText>
          </SectionGroup>

          <Button variant="primary" onClick={onCopy} testID="copy" fullWidth>
            {t('Copiar chave')}
          </Button>

          <div className="mx-auto flex w-full max-w-[180px] flex-col">
            <Box className="mx-auto my-spacing0 h-auto w-full max-w-full">
              {code && (
                <QRCode
                  size={256}
                  className={'h-auto w-full max-w-full'}
                  viewBox="0 0 256 256"
                  value={code}
                />
              )}
            </Box>

            <Typography variant="caption" align="center">
              {t('Mire a câmera do seu celular para o QRCODE')}
            </Typography>
          </div>
        </div>
      ) : null}

      <FeedbackModal
        type="error"
        isOpen={isOpenErrorModal}
        onClose={closeErrorModal}
        title={errorTitle}
        description={t(
          'Verifique as informações do seu pagamento e tente novamente.',
        )}
        footer={
          <Button variant="secondary" fullWidth onClick={closeErrorModal}>
            Fechar
          </Button>
        }
      />
    </div>
  )
}
