import { styled, Box } from '@maistodos/design-system-web'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.spacing24};

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

type ColumnProps = {
  direction?: 'row' | 'column'
}

export const Column = styled(Box)<ColumnProps>`
  flex: 1;
  row-gap: ${(props) => props.theme.spacings.spacing24};

  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
`
