import { useEffect, useMemo } from 'react'

import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import PaymentScreen from '@/modules/checkout/screens/payment'
import { env } from '@/shared/env'
import { useLinkId } from '@/shared/hooks/use-link-id'
import { PaymentFlowAnalytics } from '@/shared/utils/analytics'

const PaymentPage = () => {
  const { t } = useTranslation()
  const title = useMemo<string>(() => t('Pagamento'), [t])
  const id = useLinkId()

  const recaptchaScript = useMemo(() => {
    if (!env.VITE_RECAPTCHA_SITE_KEY) {
      return undefined
    }

    return [
      {
        src: `https://www.google.com/recaptcha/enterprise.js?render=${env.VITE_RECAPTCHA_SITE_KEY}`,
      },
    ]
  }, [])

  useEffect(() => {
    PaymentFlowAnalytics.registerPaymentProperties(id)
  }, [id])

  return (
    <>
      <Helmet script={recaptchaScript}>
        <title>{title}</title>
      </Helmet>
      <PaymentScreen id={id} title={title} />
    </>
  )
}

export default PaymentPage
