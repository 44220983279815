import { isExpirationDateValid, isValid } from 'creditcard.js'
import { TFunction } from 'i18next'
import { z } from 'zod'

import { PaymentOption } from '@/modules/checkout/_core/domain/entities'

export const createFormCardSchema = (t: TFunction) =>
  z.object({
    card: z.enum([PaymentOption.CreditCard, PaymentOption.DebitCard]),
    number: z
      .string()
      .min(1, { message: t('Informe o número do cartão') })
      .refine(isValid, { message: t('Número de cartão inválido') }),
    expDate: z
      .string()
      .min(1, { message: t('Informe a data de validade') })
      .refine(
        (value) => {
          const [month, year] = value.split('/')
          return isExpirationDateValid(month, year)
        },
        { message: t('Data de validade inválida') },
      ),
    cvc: z
      .string()
      .min(3, { message: t('Informe um código de segurança válido') })
      .min(1, { message: t('Informe o código de segurança') }),
    holder: z
      .string()
      .min(1, { message: t('Informe o nome do titular') })
      .refine((value) => value.trim().split(' ').length > 1, {
        message: t('Informe um nome válido'),
      }),
    installments: z
      .string()
      .min(1, { message: t('Informe o número de parcelas') })
      .refine(
        (v) => {
          const n = Number(v)
          return !isNaN(n) && v?.length > 0
        },
        { message: 'Invalid number' },
      ),
    isDefault: z.boolean().optional().default(false),
  })

export type FormCardData = z.infer<ReturnType<typeof createFormCardSchema>>
