import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEsCL from '@/shared/translations/locales/es_CL.json'
import translationEsCO from '@/shared/translations/locales/es_CO.json'
import translationPT from '@/shared/translations/locales/pt_BR.json'

const resources = {
  es_CO: {
    translation: translationEsCO,
  },
  es_CL: {
    translation: translationEsCL,
  },
  pt_BR: {
    translation: translationPT,
  },
} as const

i18n.use(initReactI18next).init({
  resources,
  lng: 'pt_BR',
  fallbackLng: 'pt_BR',
  supportedLngs: ['pt_BR', 'es_CO', 'es_CL'],
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
