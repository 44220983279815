import { useQuery, type UseQueryOptions } from '@tanstack/react-query'

import { Link, LocaleCode } from '@/modules/checkout/_core/domain/entities'
import { findLinkById } from '@/modules/checkout/actions/link'
import type { ApiError } from '@/shared/_core/api'

import { findQueryKey } from './keys'

export const useLink = <T = Link>(
  id: string,
  options?: Omit<
    UseQueryOptions<Link | undefined, ApiError<LocaleCode>, T>,
    'queryKey'
  >,
) => {
  return useQuery({
    queryFn: async () => {
      const response = await findLinkById(id)
      if (!response.ok) {
        throw new Error(response.error)
      }

      return response.data
    },
    queryKey: findQueryKey(id),
    enabled: Boolean(id),
    ...options,
  })
}
