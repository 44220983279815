import 'react-credit-cards-2/dist/es/styles-compiled.css'

import ReactCreditCards, { Focused } from 'react-credit-cards-2'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormCardData } from '@/modules/checkout/components/FormCard/schemas'

export const CardPreview = ({
  focused,
  setIssuer,
}: {
  focused: Focused | undefined
  setIssuer: (issuer: string) => void
}) => {
  const { t } = useTranslation()

  const { watch } = useFormContext<FormCardData>()

  const [number, expDate, cvc, holder] = watch([
    'number',
    'expDate',
    'cvc',
    'holder',
  ])

  return (
    <span className="hidden md:flex">
      <ReactCreditCards
        cvc={cvc}
        expiry={expDate}
        focused={focused}
        name={holder}
        number={number}
        placeholders={{ name: t('NOME') }}
        locale={{ valid: t('Valido até') }}
        callback={(e) => {
          setIssuer(e.issuer)
        }}
      />
    </span>
  )
}
