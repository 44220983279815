import { useState, useCallback } from 'react'

interface UseTabsProps {
  defaultTab?: string | number | null
  tabs: Array<string | number>
}

interface UseTabsReturn {
  activeTab: string | number | null
  setActiveTab: (tab: string | number) => void
  isActiveTab: (tab: string | number) => boolean
  getTabProps: (tab: string | number) => {
    role: string
    'aria-selected': boolean
    'aria-controls': string
    id: string
    tabIndex: number
    onClick: () => void
  }
  getTabPanelProps: (tab: string | number) => {
    role: string
    'aria-labelledby': string
    id: string
    hidden: boolean
  }
}

export function useTabs({ defaultTab, tabs }: UseTabsProps): UseTabsReturn {
  const [activeTab, setActiveTab] = useState<string | number | null>(
    defaultTab || tabs[0],
  )

  const isActiveTab = useCallback(
    (tab: string | number) => activeTab === tab,
    [activeTab],
  )

  const getTabProps = useCallback(
    (tab: string | number) => ({
      role: 'tab',
      'aria-selected': isActiveTab(tab),
      'aria-controls': `panel-${tab}`,
      id: `tab-${tab}`,
      tabIndex: isActiveTab(tab) ? 0 : -1,
      onClick: () => setActiveTab(tab),
    }),
    [isActiveTab],
  )

  const getTabPanelProps = useCallback(
    (tab: string | number) => ({
      role: 'tabpanel',
      'aria-labelledby': `tab-${tab}`,
      id: `panel-${tab}`,
      hidden: !isActiveTab(tab),
    }),
    [isActiveTab],
  )

  return {
    activeTab,
    setActiveTab,
    isActiveTab,
    getTabProps,
    getTabPanelProps,
  }
}
