import { StrictMode } from 'react'

import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router'

import ErrorPage from '@/app/404'
import Payment from '@/app/[id]/payment'
import Receipt from '@/app/[id]/receipt'
import Refunded from '@/app/[id]/refunded'
import App from '@/app/layout'
import { initSentry } from '@/shared/utils/sentry'

import './shared/translations/i18n'
import './index.css'

const root = createRoot(document.getElementById('root') as HTMLElement)

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/*',
        element: <ErrorPage />,
      },
      {
        path: ':string/*',
        element: <ErrorPage />,
      },
      {
        index: true,
        element: <ErrorPage />,
      },
      {
        path: ':id/',
        element: <Payment />,
      },
      {
        path: ':id/receipt',
        element: <Receipt />,
      },
      {
        path: ':id/refunded',
        element: <Refunded />,
      },
      {
        path: ':id/*',
        element: <ErrorPage />,
      },
    ],
  },
])

initSentry()

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
)
