import { useMemo } from 'react'

import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import ReceiptScreen from '@/modules/checkout/screens/receipt'
import { useLinkId } from '@/shared/hooks/use-link-id'

const ReceiptPage = () => {
  const { t } = useTranslation()
  const title = useMemo<string>(() => t('Comprovante de pagamento'), [t])
  const id = useLinkId()

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ReceiptScreen id={id} title={title} />
    </>
  )
}

export default ReceiptPage
