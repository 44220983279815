import { Box, Spinner } from '@maistodos/design-system-web'

export const PaymentLoading = () => {
  return (
    <Box
      className="flex h-[70vh] items-center justify-center md:h-[500px]"
      data-testid="loading"
    >
      <Spinner color="brand500" fontSize="h1" />
    </Box>
  )
}
