/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react'

import {
  DefaultTheme,
  StyleSheetRegistry,
  theme,
  ThemeProvider as DefaultThemeProvider,
} from '@maistodos/design-system-web'

interface ThemeProviderProps {
  newTheme?: any
  children: ReactNode
}

export const ThemeProvider = ({ newTheme, children }: ThemeProviderProps) => {
  const mergedTheme = {
    ...theme,
    ...newTheme,
  } as unknown as DefaultTheme

  return (
    <StyleSheetRegistry>
      <DefaultThemeProvider theme={mergedTheme}>
        {children}
      </DefaultThemeProvider>
    </StyleSheetRegistry>
  )
}
