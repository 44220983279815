export const downloadBlob = (filename: string, data: Blob) => {
  const myTemporaryAnchorElement = document.createElement('a')
  myTemporaryAnchorElement.download = filename
  myTemporaryAnchorElement.href = URL.createObjectURL(data)

  document.body.appendChild(myTemporaryAnchorElement)

  myTemporaryAnchorElement.click()

  URL.revokeObjectURL(myTemporaryAnchorElement.href)
  document.body.removeChild(myTemporaryAnchorElement)
}
