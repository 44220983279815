import { useTranslation } from 'react-i18next'

import { Content } from '@/components/Content'
import { LinkStatus } from '@/modules/checkout/_core/domain/entities'
import { PaymentOptions } from '@/modules/checkout/components/Payment/PaymentOptions'
import { PaymentResume } from '@/modules/checkout/components/Payment/PaymentResume'
import { PaymentTerms } from '@/modules/checkout/components/Payment/PaymentTerms'
import { PaymentLoading } from '@/modules/checkout/components/PaymentLoading'
import { PaymentNotFound } from '@/modules/checkout/components/PaymentNotFound'
import { SectionGroup } from '@/modules/checkout/components/Section/SectionGroup'
import { SectionText } from '@/modules/checkout/components/Section/SectionText'
import { SectionTitle } from '@/modules/checkout/components/Section/SectionTitle'
import { useLink } from '@/modules/checkout/hooks/use-link'
import { usePaymentCheck } from '@/modules/checkout/hooks/use-payment-check'
import { formatLocaleCustomerDocument } from '@/shared/utils/format-locale'

import { PaymentProps } from './types'

const PaymentScreen = ({ id, title }: PaymentProps) => {
  const { t, i18n } = useTranslation()
  const { data, error, isLoading } = useLink(id)
  const { isValid } = usePaymentCheck(id, LinkStatus.Created)

  if (error) {
    return <PaymentNotFound locale_code={error?.errors?.localeCode} />
  }

  if (isLoading || !isValid || !data) {
    return (
      <Content
        title={title}
        className="mx-spacing16 flex flex-col gap-spacing24 !p-spacing16 md:mx-spacing0 md:!p-spacing32"
      >
        <PaymentLoading />
      </Content>
    )
  }

  return (
    <Content
      title={title}
      className="mx-spacing16 flex flex-col gap-spacing24 !p-spacing16 md:mx-spacing0 md:!p-spacing32"
    >
      <div className="grid grid-cols-1 gap-spacing24 md:grid-cols-2">
        <section className="col-span-1 flex flex-col gap-spacing24">
          <SectionGroup>
            <SectionTitle>{t('Dados pessoais')}</SectionTitle>
            <SectionText>{data.customer.name}</SectionText>
            {data.customer.email ? (
              <SectionText>E-mail: {data.customer.email}</SectionText>
            ) : null}
            {data.customer.document ? (
              <SectionText>
                {`${t('Documento')}: ${formatLocaleCustomerDocument(
                  data.customer.document,
                  i18n.language,
                )}`}
              </SectionText>
            ) : null}
          </SectionGroup>
          <SectionGroup>
            <SectionTitle>{t('Nome do emissor')}</SectionTitle>
            <SectionText>{data.company.name}</SectionText>
          </SectionGroup>
        </section>
        <section className="relative col-span-1">
          <div className="md:absolute md:left-spacing0 md:right-spacing0 md:top-spacing0">
            <PaymentResume
              amount={data.amount}
              description={data.messages.main}
            />
          </div>
        </section>
      </div>

      <SectionGroup>
        <SectionTitle>{t('Forma de pagamento')}</SectionTitle>
        <PaymentOptions
          id={id}
          amount={data.amount}
          types={data.paymentTypes}
          installments={data.installments}
          labels={{
            defaultCard: data.acceptNewCardAsDefaultLabel,
          }}
          showSetDefaultCard={data.showSetNewCardAsDefault}
        />
      </SectionGroup>

      <PaymentTerms
        label={data.acceptTerms.label}
        file={data.acceptTerms.file}
      />
    </Content>
  )
}

export default PaymentScreen
