import { PropsWithChildren } from 'react'

import { ClassValue } from 'clsx'

import { Typography } from '@maistodos/design-system-web'

type SectionTextProps = PropsWithChildren<{
  className?: ClassValue
}>

export const SectionText = ({ className, children }: SectionTextProps) => (
  <Typography className={className} variant="medium" color="neutral600">
    {children}
  </Typography>
)
