import { Box } from '@maistodos/design-system-web'

import { cn } from '@/shared/utils/tailwind'

import { ContentProps } from './types'

export const Content = ({
  title,
  titleClassName,
  className,
  children,
}: ContentProps) => {
  return (
    <>
      <Box
        className={cn(
          'px-spacing16 pb-spacing4 !text-h6 font-bold md:p-spacing0 md:pb-spacing16 md:!text-h5 md:text-neutral900',
          titleClassName,
        )}
      >
        {title}
      </Box>

      <Box className={cn('rounded-medium bg-neutral0 p-spacing32', className)}>
        {children}
      </Box>
    </>
  )
}
