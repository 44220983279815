export const masks: Record<string, { number: string; cvc: string }> = {
  amex: {
    number: 'dddd dddddd ddddd',
    cvc: 'dddd',
  },
  'american-express': {
    number: 'dddd dddddd ddddd',
    cvc: 'dddd',
  },
  default: {
    number: 'dddd dddd dddd dddd',
    cvc: 'ddd',
  },
}

export const ONE_INSTALLMENT = 1
