'use client'

import { useEffect, useMemo } from 'react'

import { BlobProvider } from '@react-pdf/renderer'
import { captureException } from '@sentry/react'
import { useTranslation } from 'react-i18next'

import { Print } from '@maistodos/core-icons'
import { Box, Typography, Divider, Button } from '@maistodos/design-system-web'

import { Content } from '@/components/Content'
import { LinkStatus } from '@/modules/checkout/_core/domain/entities'
import { CountdownRedirect } from '@/modules/checkout/components/CountdownRedirect'
import { PaymentLoading } from '@/modules/checkout/components/PaymentLoading'
import { PaymentNotFound } from '@/modules/checkout/components/PaymentNotFound'
import { ReceiptPdf } from '@/modules/checkout/components/ReceiptPdf'
import { useLink } from '@/modules/checkout/hooks/use-link'
import { usePaymentCheck } from '@/modules/checkout/hooks/use-payment-check'
import { getSafeRedirectUrl } from '@/modules/checkout/utils/getSafeRedirectUrl'
import { PaymentFlowAnalytics } from '@/shared/utils/analytics'
import { downloadBlob } from '@/shared/utils/download-blob'
import {
  formatLocaleCurrency,
  formatLocaleCustomerDocument,
  isLanguageSupported,
} from '@/shared/utils/format-locale'

import { ReceiptProps } from './types'

const ReceiptScreen = ({ id, title }: ReceiptProps) => {
  const { t, i18n } = useTranslation()
  const { data, isLoading, error } = useLink(id)
  const { isValid } = usePaymentCheck(id, LinkStatus.Paid)

  const redirectUrl = useMemo(() => {
    if (!data?.callbackUrl) {
      return null
    }

    try {
      return getSafeRedirectUrl(data.callbackUrl, id)
    } catch (error) {
      captureException(`Error to getSafeRedirectUrl callbackUrl: ${error}`)
      return null
    }
  }, [data, id])

  useEffect(() => {
    i18n.changeLanguage(isLanguageSupported(data?.localeCode) ?? 'pt_BR')
  }, [data, i18n])

  if (error) {
    return <PaymentNotFound locale_code={error?.errors?.localeCode} />
  }

  if (isLoading || !isValid || !data) {
    return (
      <Content title={t(title)}>
        <PaymentLoading />
      </Content>
    )
  }

  return (
    <Content title={t(title)}>
      <Box className="flex flex-col gap-spacing24">
        <Box className="flex flex-col gap-spacing8">
          <Typography variant="large" weight="bold" color="neutral600">
            {t('Dados pessoais')}
          </Typography>
          <Typography variant="medium" color="neutral600">
            {data.customer.name}
          </Typography>
          {!!data.customer.email && (
            <Typography variant="medium" color="neutral600">
              {data.customer.email}
            </Typography>
          )}
          {!!data.customer.document && (
            <Typography variant="medium" color="neutral600">
              {`${t('Documento')}: ${formatLocaleCustomerDocument(
                data.customer.document,
                i18n.language,
              )}`}
            </Typography>
          )}
        </Box>

        <Box className="flex flex-col gap-spacing8">
          <Typography variant="large" weight="bold" color="neutral600">
            {t('Nome do emissor')}
          </Typography>
          <Typography variant="medium" color="neutral600">
            {data.company.name}
          </Typography>
        </Box>

        <Divider />

        <Box className="flex flex-col gap-spacing8">
          <Typography variant="large" weight="bold" color="neutral600">
            {t('Resumo do pagamento')}
          </Typography>
          <Typography variant="medium" color="neutral600">
            {data.messages.main}
          </Typography>
        </Box>

        <Box>
          <Box className="flex flex-col gap-spacing8">
            <Typography variant="medium" color="neutral400">
              {t('Valor total:')}
            </Typography>
            <Typography variant="medium" color="neutral600">
              {formatLocaleCurrency(data.amountPaid, i18n.language)}
            </Typography>
          </Box>

          <Box className="mb-spacing8 mt-spacing32 flex justify-between">
            <Typography variant="h6" color="neutral600">
              {t('Valor pago:')}
            </Typography>
            <Typography variant="h6" as="p" color="neutral600">
              {formatLocaleCurrency(data.amountPaid, i18n.language)}
            </Typography>
          </Box>
          <Divider className="mb-spacing32" />
        </Box>

        <Box className="mx-auto flex max-w-[256px] flex-col gap-spacing16">
          <BlobProvider document={<ReceiptPdf data={data} />}>
            {({ blob, loading }) => (
              <Button
                variant="primary"
                onClick={() => {
                  if (!blob) return
                  downloadBlob('recibo.pdf', blob)
                  PaymentFlowAnalytics.onClickPrint()
                }}
                rightIcon={
                  <Print color="currentColor" fontSize="calc(1em + 30%)" />
                }
                disabled={loading}
                className="w-full sm:w-auto sm:min-w-[256px]"
              >
                {t('Imprimir')}
              </Button>
            )}
          </BlobProvider>
        </Box>
      </Box>

      {redirectUrl ? <CountdownRedirect redirectUrl={redirectUrl} /> : null}
    </Content>
  )
}

export default ReceiptScreen
