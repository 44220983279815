import { SectionGroup } from '@/modules/checkout/components/Section/SectionGroup'
import { SectionText } from '@/modules/checkout/components/Section/SectionText'
import { PaymentFlowAnalytics } from '@/shared/utils/analytics'

import { PaymentTermsProps } from './types'

export const PaymentTerms = ({ label, file }: PaymentTermsProps) => {
  const [first, second] = label.split('{link}')

  return (
    <SectionGroup>
      <SectionText>
        {first}
        <a
          className="font-bold text-brand500 hover:underline"
          href={file}
          title="Termos de Uso"
          target="_blank"
          rel="noreferrer"
          onClick={PaymentFlowAnalytics.onClickSeeTerms}
        >
          Termos de Uso
        </a>{' '}
        {second}
      </SectionText>
    </SectionGroup>
  )
}
