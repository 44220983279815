import type { Dict } from 'mixpanel-browser'
import mixpanel from 'mixpanel-browser'

import { env } from '@/shared/env'

import type { MixPanelEvents } from './types'

export class MixpanelTracker {
  private static isInitialized = false

  protected static init() {
    if (!env.VITE_MIXPANEL_TOKEN) return false

    if (!MixpanelTracker.isInitialized) {
      mixpanel.init(env.VITE_MIXPANEL_TOKEN, {
        debug: import.meta.env.MODE === 'development',
        loaded: () => {
          MixpanelTracker.isInitialized = true
        },
      })
    }
    return true
  }

  public static sendEvent(eventName: MixPanelEvents, eventProperties?: Dict) {
    const isInitialized = this.init()

    if (!isInitialized) return

    mixpanel.track(eventName, {
      origin: env.VITE_MIXPANEL_APP_ORIGIN,
      ...eventProperties,
    })
  }

  public static registerGlobalProperty(properties?: Dict) {
    const isInitialized = this.init()

    if (!isInitialized) return

    if (!properties) return

    mixpanel.register(properties)
  }
}
