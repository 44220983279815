import { styled } from '@maistodos/design-system-web'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.spacing32};
  color: ${({ theme }) => theme.colors.neutral400};
`

export const IconBox = styled.span`
  color: ${({ theme }) => theme.colors.warning500};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.spacing16};
`
