import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from 'axios'

import loggerInterceptors from './interceptors'
import type {
  HttpClient,
  HttpResponse,
  HttpClientParams,
  HttpStatusCode,
} from './protocols'
import type { Interceptors } from './types'

export class AxiosAdapterHttpClient implements HttpClient {
  private axiosInstance: AxiosInstance

  constructor(config?: AxiosRequestConfig, interceptors?: Interceptors) {
    this.axiosInstance = axios.create(config)

    this.axiosInstance.interceptors.response.use(
      loggerInterceptors.responseInterceptor,
      loggerInterceptors.responseErrorInterceptor,
    )

    this.axiosInstance.interceptors.request.use(
      loggerInterceptors.requestInterceptor,
      loggerInterceptors.requestErrorInterceptor,
    )

    if (interceptors) {
      this.axiosInstance.interceptors.request.use(
        interceptors.requestInterceptor,
        interceptors.requestErrorInterceptor,
      )
      this.axiosInstance.interceptors.response.use(
        interceptors.responseInterceptor,
        interceptors.responseErrorInterceptor,
      )
    }
  }

  async get<TResponse>(
    url: string,
    params?: HttpClientParams,
    config?: { headers?: Record<string, string>; timeout?: number },
  ): Promise<HttpResponse<TResponse>> {
    const response = await this.axiosInstance.get<TResponse>(url, {
      ...config,
      params,
    })
    return {
      statusCode: response.status as HttpStatusCode,
      data: response.data,
    }
  }

  async post<TRequest, TResponse>(
    url: string,
    data: TRequest,
  ): Promise<HttpResponse<TResponse>> {
    const response = await this.axiosInstance.post<TResponse>(url, data)
    return {
      statusCode: response.status,
      data: response.data,
    }
  }

  async put<TRequest, TResponse>(
    url: string,
    data: TRequest,
  ): Promise<HttpResponse<TResponse>> {
    const response = await this.axiosInstance.put<TResponse>(url, data)
    return {
      statusCode: response.status,
      data: response.data,
    }
  }

  async patch<TRequest, TResponse>(
    url: string,
    data: TRequest,
  ): Promise<HttpResponse<TResponse>> {
    const response = await this.axiosInstance.patch<TResponse>(url, data)
    return {
      statusCode: response.status,
      data: response.data,
    }
  }

  async delete<TResponse>(url: string): Promise<HttpResponse<TResponse>> {
    const response = await this.axiosInstance.delete<TResponse>(url)
    return {
      statusCode: response.status,
      data: response.data,
    }
  }
}
