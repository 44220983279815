import * as AxiosLogger from 'axios-logger'

import type { Interceptors } from './types'

const loggerInterceptors = {
  requestInterceptor: async (config) => {
    return AxiosLogger.requestLogger(config, {
      params: true,
      headers: true,
    })
  },

  requestErrorInterceptor: (error) => {
    return AxiosLogger.errorLogger(error, { params: true })
  },

  responseInterceptor: (response) => {
    return AxiosLogger.responseLogger(response, {
      data: false,
    })
  },

  responseErrorInterceptor: (error) => {
    return AxiosLogger.errorLogger(error)
  },
} satisfies Interceptors

export default loggerInterceptors
