import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Boleto from 'boleto.js'
import { format, parse } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { Info } from '@maistodos/core-icons'
import {
  Box,
  Button,
  Skeleton,
  toast,
  Typography,
} from '@maistodos/design-system-web'

import {
  AlertContainer,
  AlertContent,
  AlertIconWrapper,
  AlertMessage,
  AlertTitle,
} from '@/components/Alert'
import { FeedbackModal } from '@/components/FeedbackModal'
import { PaymentOption } from '@/modules/checkout/_core/domain/entities'
import { SectionGroup } from '@/modules/checkout/components/Section/SectionGroup'
import { SectionText } from '@/modules/checkout/components/Section/SectionText'
import { SectionTitle } from '@/modules/checkout/components/Section/SectionTitle'
import { useModal } from '@/modules/checkout/hooks/use-modal'
import { usePayGenerate } from '@/modules/checkout/hooks/use-pay-generate'
import { PaymentFlowAnalytics } from '@/shared/utils/analytics'
import { copyToClipboard } from '@/shared/utils/copy-to-clipboard'
import { formatLocaleCurrency } from '@/shared/utils/format-locale'

import { FormBankSlipProps } from './types'

export const FormBankSlip = ({
  id,
  amount,
  paymentType,
}: FormBankSlipProps) => {
  const { t, i18n } = useTranslation()

  const bankslip = useRef<Boleto | undefined>(undefined)
  const [barcodePretty, setBarCodePretty] = useState<string>('')

  const {
    isOpen: isOpenErrorModal,
    open: openErrorModal,
    close: closeErrorModal,
  } = useModal()

  const { data, isPending, error, isError } = usePayGenerate(
    PaymentOption.BankSlip,
    id,
    paymentType.id,
  )
  const code = useMemo(() => data?.bankslip?.barcode, [data?.bankslip?.barcode])

  const formatted = useMemo<string>(
    () => formatLocaleCurrency(amount, i18n.language),
    [amount, i18n.language],
  )

  const errorTitle = useMemo(() => {
    if (error?.needs_to_pay_with_max_installments?.length) {
      return error.needs_to_pay_with_max_installments[0]
    }

    return t('Não foi possível processar seu pagamento.')
  }, [error, t])

  const expDate = useMemo<string>(() => {
    if (!data?.bankslip?.expDate) return '00/00/0000'

    const date = parse(data.bankslip.expDate, 'yyyy-MM-dd', new Date())
    return format(date, 'dd/MM/yyyy')
  }, [data])

  const onCopy = useCallback(async () => {
    PaymentFlowAnalytics.onClickCopyCode()

    await copyToClipboard(code!)

    toast.success({
      message: t('Cópia realizada com sucesso!'),
      duration: 3000,
    })
  }, [code, t])

  useEffect(() => {
    if (isError) {
      openErrorModal()
    }
  }, [isError, openErrorModal])

  useEffect(() => {
    if (!code) return
    bankslip.current = new Boleto(code)
    bankslip.current.toSVG('#barcode')
    setBarCodePretty(bankslip.current.prettyNumber())
  }, [code])

  return (
    <div>
      {isPending && (
        <div className="flex w-full flex-col gap-spacing24 md:w-1/2">
          <SectionGroup>
            <SectionTitle>{t('Data de vencimento')}</SectionTitle>
            <Skeleton height={16} />
          </SectionGroup>

          <SectionGroup>
            <SectionTitle>{t('Valor do boleto')}</SectionTitle>
            <Skeleton height={16} />
          </SectionGroup>

          <Skeleton height={60} />

          <Skeleton height={12} />

          <Button variant="primary" fullWidth disabled loading>
            {t('Copiar código de barras')}
          </Button>
        </div>
      )}

      {isError && (
        <AlertContainer variant="warning">
          <AlertIconWrapper>
            <Info color="currentColor" fontSize="24px" />
          </AlertIconWrapper>
          <AlertContent>
            {errorTitle ? <AlertTitle>{errorTitle}</AlertTitle> : null}
            <AlertMessage>
              {t(
                'Verifique as informações do seu pagamento e tente novamente.',
              )}
            </AlertMessage>
          </AlertContent>
        </AlertContainer>
      )}

      {code ? (
        <div className="flex w-full flex-col gap-spacing24 md:w-1/2">
          <SectionGroup>
            <SectionTitle>{t('Data de vencimento')}</SectionTitle>
            <SectionText>{expDate}</SectionText>
          </SectionGroup>

          <SectionGroup>
            <SectionTitle>{t('Valor do boleto')}</SectionTitle>
            <SectionText>{formatted}</SectionText>
          </SectionGroup>

          <Box className="w-full" id="barcode" />

          <Typography align="center" variant="small" weight="bold">
            {barcodePretty || '?'}
          </Typography>

          <Button variant="primary" onClick={onCopy} testID="copy" fullWidth>
            {t('Copiar código de barras')}
          </Button>
        </div>
      ) : null}

      <FeedbackModal
        type="error"
        isOpen={isOpenErrorModal}
        onClose={closeErrorModal}
        title={errorTitle}
        description={t(
          'Verifique as informações do seu pagamento e tente novamente.',
        )}
        footer={
          <Button
            size="md"
            variant="secondary"
            fullWidth
            onClick={closeErrorModal}
          >
            Fechar
          </Button>
        }
      />
    </div>
  )
}
