import type { AxiosRequestConfig } from 'axios'

import { AxiosAdapterHttpClient } from '@/shared/_core/infra/http/axios-adapter-http-client'
import type { HttpClient } from '@/shared/_core/infra/http/protocols'
import type { Interceptors } from '@/shared/_core/infra/http/types'
import { env } from '@/shared/env'

type CustomApiParams = {
  config?: AxiosRequestConfig
  interceptors?: Interceptors
}

const customApi = ({
  config,
  interceptors,
}: CustomApiParams = {}): HttpClient => {
  return new AxiosAdapterHttpClient(
    {
      baseURL: `${env.VITE_BASE_API}/v1`,
      timeout: 30000,
      ...config,
      headers: {
        'Content-Type': 'application/json',
        ...config?.headers,
      },
    },
    interceptors,
  )
}

export { customApi }
