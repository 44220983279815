import { styled } from '@maistodos/design-system-web'

export const CustomSectionGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.spacing4};
  padding-left: ${({ theme }) => theme.spacings.spacing16};
  padding-right: ${({ theme }) => theme.spacings.spacing16};
`

export const DetailsContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacings.spacing16};
  padding-bottom: ${({ theme }) => theme.spacings.spacing16};
  background-color: ${({ theme }) => theme.colors.neutral100};
  border: 1px solid ${({ theme }) => theme.colors.neutral300};
  border-radius: ${({ theme }) => theme.radii.medium};
`

export const CustomSeparator = styled.div<{ position?: 'top' | 'bottom' }>`
  ${({ position, theme }) =>
    position === 'top' &&
    `
    margin-top: ${theme.spacings.spacing16};
  `}
  ${({ position, theme }) =>
    position === 'bottom' &&
    `
    margin-bottom: ${theme.spacings.spacing16};
  `}
`

export const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.spacings.spacing16};
  padding-right: ${({ theme }) => theme.spacings.spacing16};
`

export const PriceTypography = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.h6};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutral600};
`
