import { keyframes, styled } from '@maistodos/design-system-web'

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

export const ModalContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.neutral0};
  padding: ${({ theme }) => theme.spacings.spacing32};
  border-radius: ${({ theme }) => theme.radii.large};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 100%;
  z-index: 10;

  animation: ${fadeIn} 0.3s ease-out forwards;
`

export const CloseButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spacings.spacing32};
  right: ${({ theme }) => theme.spacings.spacing32};
  background: transparent;
  border: none;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  width: ${({ theme }) => theme.spacings.spacing32};
  height: ${({ theme }) => theme.spacings.spacing32};
  border-radius: ${({ theme }) => theme.radii.circle};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacings.spacing8};

  &:hover {
    background: ${({ theme }) => theme.colors.neutral100};
    color: ${({ theme }) => theme.colors.brand500};
  }
`
