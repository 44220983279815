import { CopyOptions } from './types'

/**
 * Copies the provided text to the clipboard using the modern Clipboard API
 * with fallback to older methods for broader browser support.
 *
 * @param value - The text to copy to clipboard
 * @param options - Optional dependencies for testing purposes
 * @returns Promise<boolean> - Resolves to true if copying was successful
 * @throws Error if clipboard access is denied or not supported
 */
export const copyToClipboard = async (
  value: string,
  options: CopyOptions = {},
): Promise<boolean> => {
  // Injected or global dependencies
  const clipboard = options.clipboard ?? navigator.clipboard
  const documentObj = options.documentObj ?? document
  const isSecureContext =
    options.isSecureContext ??
    (typeof window !== 'undefined' && window.isSecureContext)
  const execCommand =
    options.execCommand ??
    ((): ((command: string) => boolean) => {
      if (documentObj.execCommand) {
        return documentObj.execCommand.bind(documentObj)
      }
      return () => false
    })()

  try {
    if (clipboard && isSecureContext) {
      await clipboard.writeText(value)
      return true
    }

    // Fallback for older browsers or non-HTTPS contexts
    const textArea = documentObj.createElement('textarea')
    textArea.value = value

    // Apply styles to hide the textarea from view
    textArea.style.cssText = `
      position: fixed;
      top: -99999px;
      left: -99999px;
      width: 2em;
      height: 2em;
      padding: 0;
      border: none;
      outline: none;
      background: transparent;
    `

    documentObj.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = execCommand('copy')
      if (!successful) {
        throw new Error('execCommand copy failed')
      }
      return true
    } finally {
      textArea.remove()
    }
  } catch (err) {
    const error = err as Error
    throw new Error(`Failed to copy: ${error.message}`)
  }
}
