import { useMutation } from '@tanstack/react-query'

import { Payment } from '@/modules/checkout/_core/domain/entities'
import { linkContainer, linkRegistry } from '@/modules/checkout/_core/inversify'
import {
  PaymentLinkByIdService,
  PaymentLinkByIdServiceTypes,
} from '@/modules/checkout/_core/services/payment-link-by-id'
import { getRecaptchaToken } from '@/shared/utils/recaptcha'

export const usePay = (id: string) => {
  return useMutation<
    Payment | undefined,
    PaymentLinkByIdServiceTypes.PaymentErrorResponse,
    PaymentLinkByIdServiceTypes.PaymentParams
  >({
    mutationFn: async (data) => {
      const { token: recaptchaToken } = await getRecaptchaToken('PAY')

      const service = linkContainer.get<PaymentLinkByIdService>(
        linkRegistry.PaymentLinkByIdService,
      )
      const response = await service.execute(id, {
        ...data,
        recaptcha_token: recaptchaToken,
      })

      let error
      try {
        error = JSON.parse(response.error!)
      } catch {
        error = response.error
      }

      if (!response.ok) {
        throw error
      }

      return response.data
    },
  })
}
