import { linkContainer, linkRegistry } from '@/modules/checkout/_core/inversify'
import { FindLinkByIdService } from '@/modules/checkout/_core/services/find-link-by-id'

export async function findLinkById(id: string) {
  const service = linkContainer.get<FindLinkByIdService>(
    linkRegistry.FindLinkByIdService,
  )

  return await service.execute(id)
}
