import { useLayoutEffect } from 'react'

import { darken, lighten } from 'polished'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { Info } from '@maistodos/core-icons'
import { Box, Toaster, Typography, theme } from '@maistodos/design-system-web'

import {
  AlertContainer,
  AlertContent,
  AlertIconWrapper,
  AlertMessage,
} from '@/components/Alert'
import { useLink } from '@/modules/checkout/hooks/use-link'
import { useRedirectPaymentStatus } from '@/modules/checkout/hooks/use-redirect-payment-status'
import { useAppEnv } from '@/shared/hooks/use-app-env'
import { useLinkId } from '@/shared/hooks/use-link-id'
import { ThemeProvider } from '@/shared/providers'
import { isLanguageSupported } from '@/shared/utils/format-locale'
import { WithChildren } from '@/types/components'

export const RootLayout = ({ children }: WithChildren) => {
  const { t, i18n } = useTranslation()
  const id = useLinkId()
  const { data } = useLink(id)

  const appEnv = useAppEnv()

  useRedirectPaymentStatus(id)

  useLayoutEffect(() => {
    i18n.changeLanguage(isLanguageSupported(data?.localeCode) ?? 'pt_BR')
  }, [data, i18n])

  const alt = `Logo ${data?.company.name}`

  const newTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      ...(data?.theme.primaryColor
        ? {
            brand50: lighten(0.5, data.theme.primaryColor),
            brand100: lighten(0.6, data.theme.primaryColor),
            brand200: lighten(0.7, data.theme.primaryColor),
            brand300: lighten(0.8, data.theme.primaryColor),
            brand400: lighten(0.9, data.theme.primaryColor),
            brand500: data.theme.primaryColor,
            brand600: darken(0.1, data.theme.primaryColor),
            brand700: darken(0.2, data.theme.primaryColor),
            brand800: darken(0.3, data.theme.primaryColor),
            brand900: darken(0.4, data.theme.primaryColor),
          }
        : undefined),
    },
  }

  document.documentElement.style.setProperty(
    '--color-brand500',
    newTheme.colors.brand500,
  )
  document.documentElement.style.setProperty(
    '--color-brand600',
    newTheme.colors.brand600,
  )
  document.documentElement.style.setProperty(
    '--color-brand700',
    newTheme.colors.brand700,
  )
  document.documentElement.style.setProperty(
    '---_main-color_hover',
    newTheme.colors.brand600,
  )
  document.documentElement.style.setProperty(
    '---_main-color_active',
    newTheme.colors.brand600,
  )

  return (
    <ThemeProvider newTheme={newTheme}>
      {data?.theme?.faviconFile && (
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={data.theme.faviconFile}
          />
        </Helmet>
      )}

      {appEnv === 'homolog' ? (
        <AlertContainer variant="warning">
          <AlertIconWrapper>
            <Info color="currentColor" fontSize="24px" />
          </AlertIconWrapper>
          <AlertContent>
            <AlertMessage>
              Você está acessando o ambiente de homologação.
            </AlertMessage>
          </AlertContent>
        </AlertContainer>
      ) : null}

      <Box
        as="header"
        className="none h-[100px] w-full items-center md:flex"
        style={{
          background: data?.theme.secondaryColor || theme.colors.neutral300,
        }}
      >
        {data && (
          <img src={data.theme.fileLogo} alt={alt} className="mx-auto h-full" />
        )}
      </Box>

      <Box
        as="main"
        className="mx-auto w-full py-spacing16 md:max-w-[722px] md:px-spacing16 md:py-spacing32"
      >
        {children}
      </Box>

      {data && (
        <Box className="flex flex-col items-center gap-spacing4 bg-neutral0 p-spacing16 md:bg-transparent">
          <img
            src={data.theme.fileLogo}
            alt={alt}
            className="aspect-square w-[49px]"
          />

          <Typography variant="small">
            {t('Todos os direitos reservados')}
          </Typography>
          <Typography variant="small" align="center">
            {data.company.name} - CNPJ {data.company.document}
            <br />
            {data.company.address}
          </Typography>
        </Box>
      )}

      <Toaster position="fixed" />
    </ThemeProvider>
  )
}
