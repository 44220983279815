import { useEffect, useState } from 'react'

import { BrowserInfo } from '../use-pay/types'

export const useBrowserInfo = (): BrowserInfo | undefined => {
  const [browserInfo, setBrowserInfo] = useState<BrowserInfo | undefined>(
    undefined,
  )

  useEffect(() => {
    try {
      const userLang = navigator.language

      setBrowserInfo({
        language: userLang || 'pt-BR',
        acceptHeader: '*/*',
        colorDepth: window.screen.colorDepth || 24,
        javaEnabled:
          typeof navigator.javaEnabled === 'function'
            ? navigator.javaEnabled()
            : false,
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
        userAgent: navigator.userAgent,
        timeZoneOffset: new Date().getTimezoneOffset(),
      })
      return
    } catch {
      setBrowserInfo(undefined)
      return
    }
  }, [])

  return browserInfo
}
