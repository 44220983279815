import { PaymentOption } from '@/modules/checkout/_core/domain/entities'
import { linkContainer, linkRegistry } from '@/modules/checkout/_core/inversify'
import { PaymentLinkByIdService } from '@/modules/checkout/_core/services/payment-link-by-id'
import { getRecaptchaToken } from '@/shared/utils/recaptcha'

type PaymentLinkByIdProps = {
  id: string
  type: PaymentOption.Pix | PaymentOption.BankSlip
  paymentType: number
}

export async function payLinkById({
  id,
  type,
  paymentType,
}: PaymentLinkByIdProps) {
  const { token: recaptchaToken } = await getRecaptchaToken('PAY')

  const service = linkContainer.get<PaymentLinkByIdService>(
    linkRegistry.PaymentLinkByIdService,
  )
  const { data: response, error } = await service.execute(id, {
    payment_type: paymentType,
    recaptcha_token: recaptchaToken,
  })

  if (error) {
    throw typeof error === 'string' ? JSON.parse(error) : error
  }

  if (
    type === PaymentOption.BankSlip &&
    (!response || !response.bankslip?.barcode || !response.bankslip?.expDate)
  ) {
    throw new Error('Não foi possível gerar o boleto')
  }

  if (type === PaymentOption.Pix && (!response || !response.pix?.qrCode)) {
    throw new Error('Não foi possível gerar o QR Code')
  }

  return response
}
