import { useMemo } from 'react'

import {
  PaymentOption,
  PaymentType,
} from '@/modules/checkout/_core/domain/entities'

import { HasPaymentOption } from './types'

export const usePaymentTypes = (types: PaymentType[]) => {
  const {
    [PaymentOption.CreditCard]: creditCard,
    [PaymentOption.DebitCard]: debitCard,
    [PaymentOption.BankSlip]: bankSlip,
    [PaymentOption.Pix]: pix,
  } = useMemo<HasPaymentOption>(() => {
    return {
      [PaymentOption.BankSlip]: types.find(
        (method) => method.type === PaymentOption.BankSlip,
      ),
      [PaymentOption.CreditCard]: types.find(
        (method) => method.type === PaymentOption.CreditCard,
      ),
      [PaymentOption.DebitCard]: types.find(
        (method) => method.type === PaymentOption.DebitCard,
      ),
      [PaymentOption.Pix]: types.find(
        (method) => method.type === PaymentOption.Pix,
      ),
    }
  }, [types])

  return {
    creditCard,
    debitCard,
    bankSlip,
    pix,
  }
}
