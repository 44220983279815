import { useQuery } from '@tanstack/react-query'

import {
  Payment,
  PaymentOption,
} from '@/modules/checkout/_core/domain/entities'
import { PaymentLinkByIdServiceTypes } from '@/modules/checkout/_core/services/payment-link-by-id'
import { payLinkById } from '@/modules/checkout/actions/payment'

import { payGenerateKey } from './key'

export const usePayGenerate = (
  type: PaymentOption.Pix | PaymentOption.BankSlip,
  id: string,
  paymentType: number,
) => {
  return useQuery<
    Payment | undefined,
    PaymentLinkByIdServiceTypes.PaymentErrorResponse
  >({
    queryKey: payGenerateKey(type, id, paymentType),
    queryFn: async () => {
      return await payLinkById({ id, type, paymentType })
    },
  })
}
