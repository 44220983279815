import {
  Customer,
  Link,
  LinkStatus,
  LinkType,
  PaymentType,
  LinkTheme,
} from '@/modules/checkout/_core/domain/entities'

import { FindLinkByIdServiceTypes } from './types'

export const toEntity = (data: FindLinkByIdServiceTypes.LinkResponse): Link => {
  return {
    localeCode: data.locale_code,
    type: data.type as LinkType,
    amount: parseFloat(data.amount),
    amountPaid: parseFloat(data.amount_paid),
    status: data.status as LinkStatus,
    messages: {
      main: data.messages.main,
      defaultCard: data.messages.default_card,
    },
    acceptNewCardAsDefaultLabel: data.accept_new_card_as_default_label,
    showSetNewCardAsDefault: data.show_set_new_card_as_default,
    maxInstallments: data.max_installments,
    installments: data.installments,
    customer: data.customer as Customer,
    company: data.company,
    theme: {
      primaryColor: data.theme.primary_color,
      hoverPrimaryColor: data.theme.hover_primary_color,
      secondaryColor: data.theme.secondary_color,
      hoverSecondaryColor: data.theme.hover_secondary_color,
      title: data.theme.title,
      fileLogo: data.theme.file_logo,
      faviconFile: data.theme.favicon_file,
    } as LinkTheme,
    acceptTerms: data.accept_terms,
    paymentTypes: data.payment_types as PaymentType[],
    callbackUrl: data.callback_url,
  } satisfies Link
}
