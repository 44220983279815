import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Creditcard, Barcode, Pix } from '@maistodos/core-icons'
import { useTheme } from '@maistodos/design-system-web'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs'
import { PaymentOption } from '@/modules/checkout/_core/domain/entities'
import { FormBankSlip } from '@/modules/checkout/components/FormBankSlip'
import { FormCard } from '@/modules/checkout/components/FormCard'
import { FormPix } from '@/modules/checkout/components/FormPix'
import { usePaymentTypes } from '@/modules/checkout/hooks/use-payment-types'
import { useTabs } from '@/shared/hooks/use-tabs'
import { PaymentFlowAnalytics } from '@/shared/utils/analytics'

import { TABS } from './constants'
import { PaymentOptionsProps } from './types'

export const PaymentOptions = ({
  id,
  amount,
  types,
  installments,
  labels,
  showSetDefaultCard,
}: PaymentOptionsProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { activeTab, getTabPanelProps, getTabProps, setActiveTab } = useTabs({
    tabs: TABS,
    defaultTab: null,
  })

  const { creditCard, debitCard, bankSlip, pix } = usePaymentTypes(types)

  const hasAnyCard = Boolean(creditCard) || Boolean(debitCard)
  const showCard = hasAnyCard && activeTab === PaymentOption.CreditCard
  const showBankslip = Boolean(bankSlip && activeTab === PaymentOption.BankSlip)
  const showPix = Boolean(pix && activeTab === PaymentOption.Pix)

  useEffect(() => {
    if (types.length === 1) {
      setActiveTab(types[0].type)
    }
  }, [setActiveTab, types])

  useEffect(() => {
    if (activeTab) {
      PaymentFlowAnalytics.onClickPaymentType(String(activeTab))
    }
  }, [activeTab])

  return (
    <Tabs>
      <TabsList>
        {hasAnyCard && (
          <TabsTrigger {...getTabProps(PaymentOption.CreditCard)}>
            <Creditcard color={theme.colors.brand500} fontSize="30px" />
            {t('Cartão')}
          </TabsTrigger>
        )}

        {bankSlip && (
          <TabsTrigger {...getTabProps(PaymentOption.BankSlip)}>
            <Barcode color={theme.colors.brand500} fontSize="30px" />
            {t('Boleto')}
          </TabsTrigger>
        )}

        {pix && (
          <TabsTrigger {...getTabProps(PaymentOption.Pix)}>
            <Pix color={theme.colors.brand500} fontSize="30px" />
            {t('Pix')}
          </TabsTrigger>
        )}
      </TabsList>

      {showCard && (
        <TabsContent {...getTabPanelProps(PaymentOption.CreditCard)}>
          <FormCard
            id={id}
            defaultCard={labels.defaultCard}
            showSetDefaultCard={showSetDefaultCard}
            creditCard={creditCard}
            debitCard={debitCard}
            installments={installments}
          />
        </TabsContent>
      )}

      {showBankslip && (
        <TabsContent {...getTabPanelProps(PaymentOption.BankSlip)}>
          <FormBankSlip amount={amount} id={id} paymentType={bankSlip!} />
        </TabsContent>
      )}

      {showPix && (
        <TabsContent {...getTabPanelProps(PaymentOption.Pix)}>
          <FormPix amount={amount} id={id} paymentType={pix!} />
        </TabsContent>
      )}
    </Tabs>
  )
}
