'use client'

import { createPortal } from 'react-dom'

import { Alert, CheckCircle } from '@maistodos/core-icons'
import { Typography, useTheme } from '@maistodos/design-system-web'

import { Container, Content, IconBox } from '@/components/FeedbackModal/styles'
import { FeedbackModalProps } from '@/components/FeedbackModal/types'
import Modal from '@/components/Modal'

export const FeedbackModal = ({
  type,
  isOpen,
  title,
  description,
  footer,
  onClose,
}: FeedbackModalProps) => {
  const theme = useTheme()

  const variants = {
    success: {
      icon: CheckCircle,
      color: theme.colors.success500,
    },
    error: {
      icon: Alert,
      color: theme.colors.warning500,
    },
  }

  const variant = variants[type]

  return createPortal(
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <Content>
          <IconBox>
            <variant.icon color={variant.color} fontSize="8rem" />
          </IconBox>
          {title && (
            <Typography
              variant="h6"
              as="h6"
              weight="bold"
              color="neutral600"
              align="center"
              css={{ margin: 0 }}
            >
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              variant="large"
              as="p"
              color="neutral500"
              align="center"
            >
              {description}
            </Typography>
          )}
        </Content>
        {footer}
      </Container>
    </Modal>,
    document.body,
  )
}
