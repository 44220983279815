import { useMemo } from 'react'

import { LinkStatus } from '@/modules/checkout/_core/domain/entities'
import { useLink } from '@/modules/checkout/hooks/use-link'

export const usePaymentCheck = (id: string, status: LinkStatus) => {
  const { data } = useLink(id, {
    notifyOnChangeProps: ['data'],
  })

  const isValid = useMemo(() => data?.status === status, [data, status])

  return {
    isValid,
  }
}
