import { Link } from '@/modules/checkout/_core/domain/entities'
import { errorHandler, responseHandler } from '@/shared/_core/api'
import type { HttpClient } from '@/shared/_core/infra/http/protocols'

import { toEntity } from './mappers'
import type { FindLinkByIdServiceTypes } from './types'

export class FindLinkByIdService {
  private readonly httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(id: string): Promise<FindLinkByIdServiceTypes.Model> {
    return this.httpClient
      .get<FindLinkByIdServiceTypes.LinkResponse>(`/links/${id}`)
      .then((response) => {
        return responseHandler<FindLinkByIdServiceTypes.LinkResponse, Link>(
          response,
          toEntity,
        )
      })
      .catch((err) => errorHandler(err))
  }
}
