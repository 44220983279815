import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Typography } from '@maistodos/design-system-web'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion'
import { SectionText } from '@/modules/checkout/components/Section/SectionText'
import { SectionTitle } from '@/modules/checkout/components/Section/SectionTitle'
import { PaymentFlowAnalytics } from '@/shared/utils/analytics'
import { formatLocaleCurrency } from '@/shared/utils/format-locale'

import {
  CustomSectionGroup,
  CustomSeparator,
  DetailsContainer,
  Price,
  PriceTypography,
} from './styles'
import { PaymentResumeProps } from './types'

export const PaymentResume = ({ description, amount }: PaymentResumeProps) => {
  const { t, i18n } = useTranslation()
  const [option, setOption] = useState<string>('')

  const toggle = (newValue: string) => {
    PaymentFlowAnalytics.onClickSeeDetails(newValue)
    setOption(newValue)
  }

  return (
    <DetailsContainer>
      <CustomSectionGroup>
        <SectionTitle>{t('Resumo do pagamento')}</SectionTitle>
        <SectionText>{description}</SectionText>
      </CustomSectionGroup>

      <CustomSeparator position="top" />

      <Accordion
        className="border-b border-t border-neutral300"
        type="single"
        collapsible
        onValueChange={toggle}
      >
        <AccordionItem value="item-1">
          <AccordionTrigger className="py-spacing16 text-neutral600 hover:bg-neutral200">
            <strong>{`Ver ${option ? t('menos') : t('mais')}`}</strong>
          </AccordionTrigger>
          <AccordionContent className="flex flex-col gap-spacing8">
            <Typography variant="medium" color="neutral400">
              {t('Valor a pagar:')}
            </Typography>
            <Typography variant="medium" color="neutral600">
              {formatLocaleCurrency(amount, i18n.language)}
            </Typography>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <CustomSeparator position="bottom" />

      <Price>
        <PriceTypography>{t('Total:')}</PriceTypography>
        <PriceTypography>
          {formatLocaleCurrency(amount, i18n.language)}
        </PriceTypography>
      </Price>
    </DetailsContainer>
  )
}
