import { Payment } from '@/modules/checkout/_core/domain/entities'

import { PaymentLinkByIdServiceTypes } from './types'

export const toEntity = (
  data: PaymentLinkByIdServiceTypes.PaymentResponse,
): Payment => {
  return {
    ...data,
    bankslip: data.bankslip && {
      barcode: data.bankslip.barcode,
      expDate: data.bankslip.exp_date,
    },
    pix: data.pix && {
      qrCode: data.pix.qr_code,
    },
  } satisfies Payment
}
