import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Typography } from '@maistodos/design-system-web'

import { Content } from '@/components/Content'
import { LinkStatus } from '@/modules/checkout/_core/domain/entities'
import { PaymentLoading } from '@/modules/checkout/components/PaymentLoading'
import { PaymentNotFound } from '@/modules/checkout/components/PaymentNotFound'
import { SectionGroup } from '@/modules/checkout/components/Section/SectionGroup'
import { SectionText } from '@/modules/checkout/components/Section/SectionText'
import { SectionTitle } from '@/modules/checkout/components/Section/SectionTitle'
import { useLink } from '@/modules/checkout/hooks/use-link'
import { usePaymentCheck } from '@/modules/checkout/hooks/use-payment-check'
import {
  formatLocaleCurrency,
  formatLocaleCustomerDocument,
  isLanguageSupported,
} from '@/shared/utils/format-locale'

import { RefundedProps } from './types'

export const RefundedScreen = ({ id, title }: RefundedProps) => {
  const { t, i18n } = useTranslation()
  const { data, isLoading, error } = useLink(id)
  const { isValid } = usePaymentCheck(id, LinkStatus.Refunded)

  useEffect(() => {
    i18n.changeLanguage(isLanguageSupported(data?.localeCode) ?? 'pt_BR')
  }, [data, i18n])

  if (error) {
    return <PaymentNotFound locale_code={error.errors?.localeCode} />
  }

  if (isLoading || !isValid || !data) {
    return (
      <Content title={t(title)}>
        <PaymentLoading />
      </Content>
    )
  }

  return (
    <Content title={t(title)}>
      <div className="flex flex-col gap-spacing24">
        {/* Customer details */}
        <SectionGroup>
          <SectionTitle>{t('Dados pessoais')}</SectionTitle>
          <SectionText>{data.customer.name}</SectionText>
          {data.customer.email && (
            <SectionText>E-mail: {data.customer.email}</SectionText>
          )}
          {!!data.customer?.document && (
            <SectionText>
              {`${t('Documento')}: ${formatLocaleCustomerDocument(
                data.customer.document,
                i18n.language,
              )}`}
            </SectionText>
          )}
        </SectionGroup>

        {/* Issuer details */}
        <SectionGroup>
          <SectionTitle>{t('Nome do emissor')}</SectionTitle>
          <SectionText>{data.company.name}</SectionText>
        </SectionGroup>

        {/* Separator */}
        <div className="h-[2px] w-full bg-neutral200" />

        <SectionGroup>
          <SectionTitle>{t('Resumo do pagamento')}</SectionTitle>
          <SectionText>{data.messages.main}</SectionText>
        </SectionGroup>

        <SectionGroup>
          <Typography variant="medium" color="neutral600">
            {t('Valor estornado:')}
          </Typography>
          <Typography variant="medium" color="neutral600">
            {formatLocaleCurrency(data.amount, i18n.language)}
          </Typography>
        </SectionGroup>
      </div>
    </Content>
  )
}
