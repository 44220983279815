import { PropsWithChildren } from 'react'

import { cva, VariantProps } from 'class-variance-authority'

import { cn } from '@/shared/utils/tailwind'

const alertContainerVariants = cva(
  'flex items-start px-spacing16 py-spacing12 rounded-md text-small font-semibold',
  {
    variants: {
      variant: {
        success: 'bg-[#D1FAE5] text-[#065F46]',
        error: 'bg-[#FEE2E2] text-[#991B1B]',
        warning: 'bg-[#FEF3C7] text-[#92400E]',
        info: 'bg-[#DBEAFE] text-[#1E40AF]',
      },
    },
    defaultVariants: {
      variant: 'info',
    },
  },
)

type AlertContainerProps = VariantProps<typeof alertContainerVariants> &
  PropsWithChildren

export const AlertContainer: React.FC<AlertContainerProps> = ({
  variant,
  children,
}) => {
  return (
    <div className={cn(alertContainerVariants({ variant }))}>{children}</div>
  )
}

export const AlertIconWrapper: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="mr-spacing8 flex h-spacing24 w-spacing24 items-center justify-center">
    {children}
  </div>
)

export const AlertContent: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="flex flex-1 flex-col items-start gap-spacing2 pt-spacing2">
    {children}
  </div>
)

export const AlertTitle: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="flex items-center justify-center text-medium font-bold">
    {children}
  </div>
)

export const AlertMessage: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="flex-1">{children}</div>
)
